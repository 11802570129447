import React from 'react'
import sectionimg from "../assets/images/sec8img.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import img1 from "../assets/images/sec8img1.svg"
import img2 from "../assets/images/sec8img2.svg"
import img3 from "../assets/images/sec8img3.svg"

const HomeSec8 = () => {
  return (
    <div className='h-section-8'>
      <div className='sec-8-top-1'>
        <div className='row g-4'>
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='sec8-col-1'>
              <h1 className='h-text-1'>
                Game-Fi
              </h1>
              <div>
                <p className='p-text-1'>
                  Soon you'll be able to Stake, Rent & do much more with your <br className='break'/>
                  assets with BIG De-Fi opportunities on the way 💸
                </p>
                <button className='btn-know-more'>
                  KNOW MORE
                </button>
              </div>
            </div>
          </div>
          <div className='col-lg-6 col-6 col-12'>
            <LazyLoadImage src={sectionimg} alt='image' className='img-fluid' />
          </div>
        </div>
      </div>
      <div className='sec-8-top-2'>
        <div className='row g-3'>
          <div className='col-lg-4 col-md-6 col-12'>
            <div className='card '>
              <div className='card-left'>
                <div className='coming-2'>
                  <p>COMING 2025/26</p>
                </div>
                <p className='p-text-2'>Stake NFTS and</p>
                <p className='p-text-3'>Earn EPAC</p>
              </div>
              <LazyLoadImage src={img1} alt='cardimg' className='card-img img-fluid' />
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-12'>
            <div className='card '>
              <div className='card-left'>
                <div className='coming-2'>
                  <p>COMING 2025/26</p>
                </div>
                <p className='p-text-2'>Rent and Lease</p>
                <p className='p-text-3'>NFTs</p>
              </div>
              <LazyLoadImage src={img2} alt='cardimg' className='card-img img-fluid' />
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-12'>
            <div className='card '>
              <div className='card-left'>
                <div className='coming-2'>
                  <p>COMING 2025/26</p>
                </div>
                <p className='p-text-2'>Liquidity Provision</p>
                <p className='p-text-3'>EPAC Dex</p>
              </div>
              <LazyLoadImage src={img3} alt='cardimg' className='card-img img-fluid' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeSec8
