import React, { useEffect, useState, useTransition } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Link } from 'react-router-dom'
import logo from "../../assets/images/logo.svg"
import menu from "../../assets/images/navmenu.svg"
import close from "../../assets/images/navclose.svg"
const Navbar = () => {
  const [isNavbarDark, setIsNavbarDark] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('home-section-3');
      const navbar = document.querySelector('.top-navbar');

      if (section) {
        const sectionTop = section.getBoundingClientRect().top;
        const navbarHeight = navbar.offsetHeight;

        if (sectionTop <= navbarHeight) {
          setIsNavbarDark(true);
        } else {
          setIsNavbarDark(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (
    <div className={`top-navbar ${isNavbarDark ? 'navbar-dark' : ''} ${isOpen ? 'menu-open' : ''}`}>
      <div className='navbar'>
        <div className='nav-start'>
          <Link to="/">
            <LazyLoadImage src={logo} alt='logo' className='logo' />
          </Link>

        </div>
        <div className='nav-end '>
          <div className='nav-end-btn mobile-hide'>
            <button className='nav-btn-download'>
              DOWNLOAD EPICADE
            </button>
          </div>
          <div className='nav-end-btn mobile-hide'>
            <button className='nav-btn-download'>
              XPLORE
            </button>
          </div>
          <button className='desktop-hide tab-hide nav-toggle-menu' onClick={toggleMenu}>
            {
              isOpen ?
                <LazyLoadImage src={close} alt='menu' className='img-fluid' /> : <LazyLoadImage src={menu} alt='menu' className='img-fluid' />
            }
          </button>
        </div>
      </div>

      {
        isOpen ? <div className='mobile-menu'>
          <div className='nav-end-btn desktop-hide tab-hide'>
            <button className='nav-btn-download nav-btn-mobile-download'>
              DOWNLOAD EPICADE
            </button>
          </div>
          <div className='nav-end-btn desktop-hide tab-hide'>
            <button className='nav-btn-download nav-btn-mobile-download'>
              XPLORE
            </button>
          </div>
        </div>
          : ""
      }
    </div>
  )
}

export default Navbar;
