import React from 'react';
import teezpatti from "../assets/images/games/teezpatti.svg";
import teezlogo from "../assets/images/games/teezpattilogo.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import fantasy from "../assets/images/games/fantasy.svg"
import fantasylogo from "../assets/images/games/fantasylogo.svg"
import teezludo from "../assets/images/games/teezludo.svg"
import teezludologo from "../assets/images/games/teezludologo.svg"
import teezladder from "../assets/images/games/teezladder.svg"
import teezladderlogo from "../assets/images/games/teezladderlogo.svg"
import sladder from "../assets/images/games/sladder.svg"
import sladderlogo from "../assets/images/games/sladderlogo.svg"
import fruitfight from "../assets/images/games/ff.svg"
import fruitfightlogo from "../assets/images/games/fflogo.svg"
import ballpool from "../assets/images/games/ballpool.svg"
import bplogo from "../assets/images/games/bplogo.svg"
import sludo from "../assets/images/games/sludo.svg"
import sllogo from "../assets/images/games/sllogo.svg"
import superarchery from "../assets/images/games/superarchery.svg"
import salogo from "../assets/images/games/salogo.svg"
import casual from "../assets/images/games/casual.svg"
import casuallogo from "../assets/images/games/casuallogo.svg"
import dfootball from "../assets/images/games/dfootball.svg"
import dflogo from "../assets/images/games/dflogo.svg"
import callcard from "../assets/images/games/callcard.svg"
import cclogo from "../assets/images/games/cclogo.svg"
import bsmash from "../assets/images/games/bsmash.svg"
import bslogo from "../assets/images/games/bslogo.svg"
import carrom from "../assets/images/games/carrom.svg"
import carromlogo from "../assets/images/games/carromlogo.svg"
import knife from "../assets/images/games/knife.svg"
import knifelogo from '../assets/images/games/knifelogo.svg'



const gamesData = [
    { id: 1, image: teezpatti, logo: teezlogo, label: 'NEW LAUNCH 🚀' },
    { id: 2, image: fantasy, logo: fantasylogo, label: 'NEW LAUNCH 🚀' },
    { id: 3, image:  teezludo, logo: teezludologo, label: 'NEW LAUNCH 🚀' },
    { id: 4, image: teezladder, logo: teezladderlogo, label: 'NEW LAUNCH 🚀' },
    { id: 5, image: sladder, logo: sladderlogo, label: 'Board 🎲' },
    { id: 6, image: fruitfight, logo: fruitfightlogo, label: 'Casual 🍉' },
    { id: 7, image: ballpool, logo: bplogo, label: 'Sports 🏹' },
    { id: 8, image: sludo, logo: sllogo, label: 'Board 🎲' },
    { id: 9, image: superarchery, logo: salogo, label: 'Sports 🏹' },
    { id: 10, image: casual, logo: casuallogo, label: 'Casual 🍉' },
    { id: 11, image: dfootball, logo: dflogo, label: 'Sports 🏹' },
    { id: 12, image: callcard, logo:cclogo, label: 'Card ♦' },
    { id: 13, image: bsmash, logo: bslogo, label: 'Casual 🍉'  },
    { id: 14, image: carrom, logo:carromlogo, label:'Board 🎲' },
    { id: 15, image: knife, logo: knifelogo, label:  'Casual 🍉' },
];

const ExcitingGames = () => {
    return (
        <section className='exciting-games'>
            <h1 className='h-text-1'>Our Exciting <br />Games</h1>
            <div className='game-grid'>
                {gamesData.map((game) => (
                    <div className='game' key={game.id}>
                        <LazyLoadImage src={game.image} alt={`game-${game.id}`} className='game-img' />
                        <div className='game-bottom'>
                            <LazyLoadImage src={game.logo} alt={`game-logo-${game.id}`} className='game-logo' />
                            <p>{game.label}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default ExcitingGames;
