import React from 'react'
import HBanner from '../components/HBanner'
import HomeSec4 from '../components/HomeSec4'
import ExcitingGames from '../components/ExcitingGames'
import Homesec7 from '../components/Homesec7'
import HomeSec3 from '../components/HomeSec3'
import HomeSec6 from '../components/Homesec6'
import HomeSec8 from '../components/HomeSec8'
import HomeSec9 from '../components/HomeSec9'


const Homepage = () => {
    return (
        <div className='homepage'>
            <HBanner />
            <HomeSec3 />
            <HomeSec4 />
            <ExcitingGames />
            <HomeSec6 />
            <Homesec7 />
            <HomeSec8 />
            <HomeSec9/>
        </div>
    )
}

export default Homepage
