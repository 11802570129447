import React from 'react'
import bannerbg from "../assets/images/bannerbg.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import telegram from "../assets/images/telegram-icon.svg"
import twitter from "../assets/images/x-icon.svg"
import { Link } from 'react-router-dom'

const HBanner = () => {
    return (
        <div className='h-banner'>
            {/* <img src={bannerbg} alt='banner' className='banner-img'/> */}
            <div className='banner-content'>
                <div className='ban-center'>
                    <h1 className='h-text-1'>Explore our Gaming <br />Universe</h1>
                    <p className='p-text-1'>Play, Compete & Earn</p>
                </div>
                <p className='p-text-2'>Available in India 🇮🇳  ·  Global 2024 🌍</p>

                <div className='ban-end'>
                    <p className='p-text-3'>Join <span> 7M+ </span>
                        MONTHLY <br />  <span> ACTIVE USERS</span></p>
                    <div className='ban-right'>
                        <p className='p-text-4'>
                            <Link to="https://t.me/epicade0">
                                <LazyLoadImage src={telegram} alt='telegram' />
                            </Link>
                            212K+ Subscribers
                        </p>
                        <p className='p-text-4'>
                        <Link to="https://x.com/EPICADE2 ">
                                <LazyLoadImage src={twitter} alt='telegram' />
                            </Link>
                            3K+ Followers
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HBanner
