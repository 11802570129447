import React from 'react'
import funimg from "../assets/images/funimg.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import cardimg1 from "../assets/images/onevsone.svg"
import cardimg2 from "../assets/images/trophy.svg"
import cardimg3 from "../assets/images/groupvsgroup.svg"

const Homesec6 = () => {
  return (
    <div className='h-section-6'>
      <div className='sec-6-content'>
        <div className='row g-4'>
          <div className='col-lg-6 col-md-12 col-12'>
            <div className='sec6-left'>
              <h1 className='h-text-1'>Fun First!</h1>
              <p className='p-text-1'>AAA Casual Games to unlock the champion in you</p>
              <p className='p-text-2'>
                Incredible physics and built with ❤️ by our Gaming Studio. You can  <br />
                play PvP, Tournament style and also in exciting Clan Battles 🏆
              </p>
              <button className='btn-epac'>
                DOWNLOAD EPAC
              </button>
              <p className='p-text-3'>
                Available in India 🇮🇳  ·  Global 2024 🌍
              </p>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-12'>
            <LazyLoadImage src={funimg} alt='' className='img-fluid fun-img' />
          </div>
        </div>
      </div>
      <div className='sec6-content-2'>
        <div className='row g-3 mt-5'>
          <div className='col-lg-4 col-md-6 col-12'>
            <div className='card sec6-card'>
              <div className='card-left'>
                <div className='live'>
                  <p>LIVE</p>
                </div>
                <p className='p-text-4'>Player v/s Player</p>
              </div>
              <LazyLoadImage src={cardimg1} alt='cardimg' className='card-img img-fluid' />
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-12'>
            <div className='card sec6-card'>
              <div className='card-left'>
                <div className='live'>
                  <p>LIVE</p>
                </div>
                <p className='p-text-4'>Tournament</p>
              </div>
              <LazyLoadImage src={cardimg2} alt='cardimg' className='card-img img-fluid' />
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-12'>
            <div className='card sec6-card'>
              <div className='card-left'>
                <div className='live'>
                  <p className='coming-soon'>Coming Soon</p>
                </div>
                <p className='p-text-4'>Clan Battles</p>
              </div>
              <LazyLoadImage src={cardimg3} alt='cardimg' className='card-img img-fluid' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Homesec6
