import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./assets/css/style.css"
import Navbar from "./components/layouts/Navbar";
import Homepage from "./pages/Homepage";
import Footer from "./components/layouts/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar />        
        <Routes>
          <Route path="/" element={<Homepage />} />
        </Routes>
        <Footer/>
      </BrowserRouter>

    </div>
  );
}

export default App;
