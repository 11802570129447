import React from 'react'
import logo from "../../assets/images/logo2.svg"
import { Link } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footer-r'>
                <Link to="/" >
                    <LazyLoadImage src={logo} alt='logo' className='f-logo'/>
                </Link>
                <div className='foot-link-div'>
                    <Link to="/terms-and-condition" className='foot-link'>
                        Terms & Conditions
                    </Link>
                    <Link to="/privacy-policy" className='foot-link'>
                       Privacy Policy
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Footer
