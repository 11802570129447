import React from 'react'
import telegram from "../assets/images/tele2.svg"
import groupimg from "../assets/images/groupimg.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Marquee from "react-fast-marquee";
import softbank from "../assets/images/softbank.svg"
import tribal from "../assets/images/tribal.svg"
import republic from "../assets/images/republic.svg"
import polygon from "../assets/images/polygon.svg"
import tiger from "../assets/images/tiger.svg"
import tencent from "../assets/images/tencent.svg"
import foxconn from "../assets/images/foxconn.svg"
import bharti from "../assets/images/bharti.svg"
import cardimg1 from "../assets/images/sec9c1.svg"
import cardimg2 from "../assets/images/sec9c2.svg"
import cardimg3 from "../assets/images/sec9c3.svg"
import { Link } from 'react-router-dom';

const HomeSec9 = () => {
    return (
        <div className='h-section-9'>
            <div className='sec-9-top-1'>
                <div className='row g-4'>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <div className='sec9-col-1'>
                            <p className='p-text-1'>BEGINNINGS OF</p>
                            <h1 className='h-text-1'>Join 212K+ <br />Members</h1>
                            <p className='p-text-2'>Your voice matter #ChangeTheGame🏆</p>
                            <p className='p-text-3'>
                                Want to be part of building the Epicade Gaming Universe's Future? <br />
                                Join us as we decentralise step by step into a DAO
                            </p>

                            <Link to="https://t.me/epicade0">
                                <LazyLoadImage src={telegram} alt='telegram' />
                            </Link>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-12'>
                        <LazyLoadImage src={groupimg} alt='group' className='img-fluid' />
                    </div>
                </div>
            </div>
            <div className='sec-9-top-2'>
                <h1 className='h-text-1'>
                    Backed by Iconic Investors <br className='break' />
                    & Product Builders
                </h1>
                <div className='sec9-img-row'>
                    <LazyLoadImage src={softbank} alt='image' className='img-fluid' />
                    <LazyLoadImage src={republic} alt='image' className='img-fluid' />
                    <LazyLoadImage src={tribal} alt='image' className='img-fluid' />
                    <LazyLoadImage src={polygon} alt='image' className='img-fluid' />
                    <LazyLoadImage src={softbank} alt='image' className='img-fluid' />
                    <LazyLoadImage src={tiger} alt='image' className='img-fluid' />
                    <LazyLoadImage src={tencent} alt='image' className='img-fluid' />
                    <LazyLoadImage src={foxconn} alt='image' className='img-fluid' />
                    <LazyLoadImage src={bharti} alt='image' className='img-fluid' />
                    <LazyLoadImage src={softbank} alt='image' className='img-fluid' />
                </div>

            </div>
            {/* <div className='sec-9-top-3'>
                <p className='p-text-4'>JUST IN CASE YOU'VE MISSED</p>
                <h4 className='h-text-2'>EPAC IS MAKING HEADLINES</h4>
                <div className='sec9-card'>
                    <div className='row g-4'>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card'>
                                <LazyLoadImage src={cardimg1} alt='' className='card-img-top img-fluid' />
                                <div className='card-body'>
                                    <p className='p-text-5'>BUSINESS INSIDER INDIA</p>
                                    <p className='p-text-6'>Rush Gaming Universe
                                        announces whitepaper with a
                                        roadmap to create new...</p>
                                    <button className='btn-read'>
                                        READ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card'>
                                <LazyLoadImage src={cardimg2} alt='' className='card-img-top img-fluid' />
                                <div className='card-body'>
                                    <p className='p-text-5'>BLOCKWORKDS</p>
                                    <p className='p-text-6'>
                                        India’s Play-to-earn Gaming
                                        Startup Unveils Token on Heels
                                        of Fundraise
                                    </p>
                                    <button className='btn-read'>
                                        READ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-12'>
                            <div className='card'>
                                <LazyLoadImage src={cardimg3} alt='' className='card-img-top img-fluid' />
                                <div className='card-body'>
                                    <p className='p-text-5'>COIN CRUNCH INDIA</p>
                                    <p className='p-text-6'>
                                    Hike’s Rush Gaming Universe 
                                    Releases Whitepaper #shorts
                                    </p>
                                    <button className='btn-read'>
                                        READ
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default HomeSec9
