import React from 'react'
import sectionimg from "../assets/images/sectionimg.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import arrowline from "../assets/images/arrowline.svg"

const HomeSec3 = () => {
    return (
        <section className='h-section-3' id="home-section-3">
            <div className='sec-3-left'>
                <div>
                    <h1 className='h-text-2'>Fast Growing <br />Community</h1>
                    <p className='p-text-3'>7M + Monthly <br />Active Users</p>
                    <p className='p-text-4'>In just 37 months of launch ️🚀</p>
                </div>
                
                <LazyLoadImage src={sectionimg} alt='sectionimg' className='section-img tab-hide mobile-hide' />
            </div>
        </section>
    )
}

export default HomeSec3
