import React from 'react'
import virtualimg from "../assets/images/virtual.svg"
import { LazyLoadImage } from 'react-lazy-load-image-component'
import trophyguy from "../assets/images/trophyguy.svg"
import manstar from "../assets/images/manstar.svg"
import token from "../assets/images/token.svg"


const Homesec7 = () => {
    return (
        <div className='h-section-7'>
            <div className="sec-content-7-1">
                <div className='row g-4'>
                    <div className='col-lg-6 col-md-12 col-12 sec-7-col-1 '>
                        <p className='p-text-1'>INTRODUCING</p>
                        <h1 className='h-text-2'>Ownership like <br />never before</h1>
                        <p className='p-text-2'>Coin of the realm</p>
                        <p className='p-text-3'>
                            The EPICADE Token is how we bring this all together. A very  <br />
                            important pillar in building a brand new gaming economy  <br />
                            where you are the true owner 😎
                        </p>
                        <div className='row g-4'>
                        <div className='col-lg-8 col-md-6 col-12'>
                            <div className='card'>
                                <div className='card-left'>
                                    <div className='live'>
                                        <p>LIVE</p>
                                    </div>
                                    <p className='p-text-4'>Win big in</p>
                                    <p className='p-text-5'>Tournaments</p>
                                </div>
                                <LazyLoadImage src={trophyguy} alt='cardimg' className='card-img img-fluid' />
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-6 col-12'>
                            <div className='card'>
                                <div className='card-left'>
                                    <div className='coming'>
                                        <p>COMING 2025/26</p>
                                    </div>
                                    <p className='p-text-4'>Update Your</p>
                                    <p className='p-text-5'>NFT Level</p>
                                </div>
                                <LazyLoadImage src={manstar} alt='cardimg' className='card-img img-fluid' />
                            </div>
                        </div>
                        </div>                   
                    </div>
                    <div className='col-lg-6 col-md-12 col-12 sec-7-col-2'>
                        <div className='sec-7-top-1'>
                            <p className='p-text-1'>40% owned <br />by the Community</p>
                            <h5 className='h-text-3'>THE EPAC <br/>TOKEN</h5>
                        </div>
                        <LazyLoadImage src={token} alt='token' className='token-img img-fluid'/>
                    </div>
                </div>
            </div>
            <div className="sec-content-7-2">
                <h1 className='h-text-1'>
                    A True Virtual Nation
                </h1>
                <LazyLoadImage src={virtualimg} alt='' className='img-fluid' />
            </div>
        </div>
    )
}

export default Homesec7
