import React from 'react'

const HomeSec4 = () => {
    return (
        <div className='h-section-4'>
            <section className='h-section-4'>
                <div className='row g-0'>
                    <div className='col-lg-4 col-md-4 col-12'>
                        <div className='sec4-card card-1'>
                            <h1 className='h-text-2'>$605M+</h1>
                            <p className='p-text-1'>Annualised Gross Revenue</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-12'>
                        <div className='sec4-card card-2'>
                            <h1 className='h-text-2'>572 M+</h1>
                            <p className='p-text-1'>GamePlays per Quarter</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-12'>
                        <div className='sec4-card  card-3'>
                            <h1 className='h-text-2'>33 M+</h1>
                            <p className='p-text-1'>Time Spends per day</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default HomeSec4
